<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="light" size="sm" :to='{name: "emails"}'>
          &laquo; Back
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card>
        <b-card-text v-if="email">
          <strong>Subject</strong>: {{ email.subject }}
          <br><br>
          <strong>Message</strong>: <br>
          <span v-html="email.message" class="d-block bg-light p-3 mt-3"></span><br>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
//import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
export default {
    components: {
      PageMenu
    },
    data(){
      return {
        page: {
            title: "Email Details",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails",
                url: this.$router.resolve({name: "emails"}).href
            },
            {
              title: "Email Details"
            }
        ],
        email: null
      }
    },
    methods: {

    },
    mounted(){
      this.$api.get(`email/${this.$route.params.emailId}`).then((res) => {
        this.email = res.data;
      });
      this.$emit('set-sidebar',{active: "emails"});
    },
};
</script>
